<template style="background:white">
  <v-container class="CompleteDataStart">
    <v-row justify="center">
      <v-col cols="12" align="center">
        <h3> {{$t('Completeyourdata')}}</h3> 
        <p class="body-2">{{$t('Verificationprocessonlytakes2x24hours')}}</p>
      </v-col>
      <v-col cols="6">
        <v-img 
          :src="require('../../assets/kyc/completeData.png')"></v-img>
      </v-col>
      <v-col cols="10" align="center">
        <p>{{$t('Pleasepreparestudentidentity')}}</p>
      </v-col>
    </v-row>
    <div class="footer">
      <v-row justify="center">
        <v-col class="text-center" cols="8">
          <v-btn
            rounded
            block
            color="primary"
            width="80%"
            :disabled="buttonDone"
            @click="clickIdentityCardKyc()"
          >
            {{ $t("Start") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>

  export default {
    name:"CompleteDataStart",
    components: { },
    data() {
      return {
        notifSuccess:false,
        notifFailed:false,

        notifdontActivePromotion:true,
        notifAvailableUnderAged:true,
        notifAvailableStudent:true,
        notifAvailableTransit:true,
        pinActiveUnderAged: "",
        pinActiveStudent: "",
        pinActiveTransit: "",
        typePromo:[],
        dataTypePromo:[
          {promo_id: "57", promo_name: "Under-Aged Student Promotion"},
          {promo_id: "58", promo_name: "Student Promotion"},
          {promo_id: "59", promo_name: "Transit Promotion Fare"}
        ],
      }
    },
    methods:{ 
      clickIdentityCardKyc(){
        this.$router.push({
          name: 'CameraOnIdentityCardKyc',
        })
      },
      
      
    },
    mounted(){
      // console.log("params", this.$route.params.items)
    }
  }
</script>


<style lang="scss" scoped>
.div-template {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.rounded-card {
  border-radius: 15px !important;
  background: linear-gradient(180deg, #FFFFFF 0%, #FFE9FC 100%);
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 70px;
  text-align: center;
  background: #ffffff;
  mix-blend-mode: normal;
}
</style>